import { usePathname } from 'next/navigation';
import React from 'react';

import { BROWSER_ENVIRONMENT, GIFT_URL } from '../../globals';
import { NavigationLink } from '../shared/NavigationLink';
import * as S from './Header.styles';

const Navigation = (): JSX.Element => {
  const pathname = usePathname();

  return (
    <>
      <S.NavigationScience isActive={pathname === '/science'}>
        <NavigationLink action="Our Science Header Click" link="/science" text="Our Science" />
      </S.NavigationScience>
      <S.NavigationAbout isActive={pathname === '/about'}>
        <NavigationLink action="About Us Header Click" link="/about" text="About Us" />
      </S.NavigationAbout>
      {BROWSER_ENVIRONMENT === 'development' ? (
      <S.NavigationAbout isActive={pathname === '/download'}>
        <NavigationLink action="Header Download Click" link="/download" text="Download" />
      </S.NavigationAbout>
      ) : null}
      <S.NavigationGift isActive={pathname === GIFT_URL}>
        <NavigationLink action="Gift Click" link={GIFT_URL} text="Gift" />
      </S.NavigationGift>
    </>
  );
};

export default Navigation;
